import React, { useContext, useState } from "react";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import {
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  ListItemIcon
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { UserContext } from "../../App";
import { useSignOut } from "../../hooks/useSignout";
import styles from "./Header.module.scss";

function Header() {
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useNavigate();
  const [signOut] = useSignOut();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Toolbar className={styles.root}>
      <div className={styles.headerWrapper}>
        {user ? (
          <div className={styles.leftSide}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              component={Link}
              to="/university"
            >
              <AmplifyS3Image imgKey={user.university?.logo} />
            </IconButton>
            <Typography
              className={styles.pointer}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              onClick={() => history("/university")}
            >
              {user.university.name}
            </Typography>
          </div>
        ) : (
          <CircularProgress />
        )}
        <div className={styles.rightSide}>
          {user && (
            <>
              Hi,
              <Button
                sx={{color: 'white'}}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <i>{user.email}</i>
                <ArrowDropDownIcon />
              </Button>
              <React.Fragment>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={signOut}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            </>
          )}
        </div>
      </div>
    </Toolbar>
  );
}

export default Header;
