import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { Content, Playlist } from "../../../models";
import styles from "./PlaylistPage.module.scss";

function PlaylistPage() {
  const { id } = useParams();
  const [playlist, setPlaylist] = useState<Playlist>();
  const [contents, setContents] = useState<Content[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (id) {
        const getPlaylist = await DataStore.query(Playlist, (c) =>
          c.id("eq", id)
        );
        setPlaylist(getPlaylist[0]);
        const getContents = await DataStore.query(Content, (c) =>
          c.playlistID("eq", id)
        );
        setContents(getContents);
        setIsLoading(false);
      }
    })();
  }, [isLoading]);

  const contentQuiz = contents.filter((content: any) => content.Quiz !== null);
  const contentVideo = contents.filter(
    (content: any) => content.Video !== null
  );
  const contentSurvey = contents.filter(
    (content: any) => content.Survey !== null
  );

  return (
    <>
      <div className={styles.root}>
        {playlist && (
          <h3>
            Playlist: <span>{playlist.title}</span>
          </h3>
        )}
        {!contents ? (
          "No contents found"
        ) : (
          <>
            {contentQuiz.length > 0 ? (
              <>
                <p>{contentQuiz.length === 1 ? "- Quiz" : "- Quizzes"}</p>
                {contentQuiz.map((content) => {
                  const { Quiz: quiz } = content;
                  return (
                    <NavLink
                      key={quiz?.id}
                      to={`quiz/${quiz?.id}`}
                      className={({ isActive }) =>
                        isActive ? `${styles.active}` : `${styles.inactive}`
                      }
                    >
                      {content.title}
                    </NavLink>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {contentVideo.length > 0 ? (
              <>
                <p>{contentVideo.length === 1 ? "- Video" : "- Videos"}</p>
                {contentVideo.map((content) => {
                  const { Video: video } = content;
                  return (
                    <NavLink
                      key={video?.id}
                      to={`video/${video?.id}`}
                      className={({ isActive }) =>
                        isActive ? `${styles.active}` : `${styles.inactive}`
                      }
                    >
                      {content.title}
                    </NavLink>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {contentSurvey.length > 0 ? (
              <>
                <p>{contentSurvey.length === 1 ? "- Survey" : "- Surveys"}</p>
                {contentSurvey.map((content) => {
                  const { Survey: survey } = content;
                  return (
                    <NavLink
                      key={survey?.id}
                      to={`survey/${survey?.id}`}
                      className={({ isActive }) =>
                        isActive ? `${styles.active}` : `${styles.inactive}`
                      }
                    >
                      {content.title}
                    </NavLink>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <Outlet />
    </>
  );
}

export default PlaylistPage;
