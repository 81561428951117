import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import styles from "./StudentPage.module.scss";
import { useParams } from "react-router-dom";
import { Playlist, Student } from "../../../models";

interface DisplayStudent extends Student {
  studentsPlaylists: (
    | { playlistId: string | undefined; playlistTitle: string | undefined }
    | string
    | null
  )[];
}

function StudentPage() {
  const { id } = useParams();
  const [student, setStudent] = useState<Student>();
  const [allPlaylists, setAllPlaylists] = useState<Playlist[]>();
  const [displayStudent, setDisplayStudent] = useState<DisplayStudent>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchStudentCompletedPlaylist = async () => {
    if (student && allPlaylists) {
      const studentsPlaylists = await Promise.all(
        student.completedPlaylists.map(async (cP: any) => {
          const playlist = allPlaylists.find((p) => p.id === cP);

          return {
            playlistId: playlist?.id,
            playlistTitle: playlist?.title,
          };
        })
      );
      setDisplayStudent({ ...student, studentsPlaylists });
    }
  };

  useEffect(() => {
    (async () => {
      if (id) {
        const getStudent = await DataStore.query(Student, (c) =>
          c.id("eq", id)
        );
        setStudent(getStudent[0]);
        const getPlaylists = await DataStore.query(Playlist);
        setAllPlaylists(getPlaylists);
        setIsLoading(false);
        await fetchStudentCompletedPlaylist();
      }
    })();
  }, [isLoading]);

  return (
    <div className={styles.root}>
      {student && (
        <>
        <div className={styles.header}>
          <h3>Username: <span>{student.username}</span></h3>
          <h3>Email: <span>{student.email}</span></h3>
          </div>
          <div className={styles.playlists}>
            <div className={styles.title}>Completed Playlists:</div>
            <ul>
              {displayStudent?.studentsPlaylists.length !== 0 ? (
                displayStudent?.studentsPlaylists.map((cP: any) => (
                  <li key={cP.playlistId}>
                    <b>{cP.playlistTitle}</b>
                  </li>
                ))
              ) : (
                <b>No playlist completed</b>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

export default StudentPage;
