import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Navigation.module.scss";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import HomeIcon from "@mui/icons-material/Home";

function Navigation() {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarWrapper}>
        <div className={styles.sidebarMenu}>
          <h3 className={styles.sidebarTitle}>Dashboard</h3>
          <ul className={styles.sidebarList}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? `${styles.active}` : `${styles.inactive}`
              }
            >
              <HomeIcon className={styles.sidebarIcon} />
              Home
            </NavLink>
            <NavLink
              to="/playlists"
              className={({ isActive }) =>
                isActive ? `${styles.active}` : `${styles.inactive}`
              }
            >
              <ListIcon className={styles.sidebarIcon} />
              Playlists
            </NavLink>

            <NavLink
              to="/students"
              className={({ isActive }) =>
                isActive ? `${styles.active}` : `${styles.inactive}`
              }
            >
              <PersonIcon className={styles.sidebarIcon} />
              Students
            </NavLink>
            <NavLink
              to="/university"
              className={({ isActive }) =>
                isActive ? `${styles.active}` : `${styles.inactive}`
              }
            >
              <SchoolIcon className={styles.sidebarIcon} />
              University
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
