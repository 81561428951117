import React, { useContext, useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import { Typography, TextField, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { UserContext } from "../../App";
import { Contact, University } from "../../models";
import styles from "./UniversityPage.module.scss";

function UniversityPage() {
  const { user } = useContext(UserContext);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isFocused, setIsFocused] = useState({
    contact: false,
    policy: false,
    legal: false,
  });
  const [info, setInfo] = useState({
    contact: user?.university.contact as string,
    legal: user?.university.legal as string,
    policy: user?.university.policy as string,
  });

  useEffect(() => {
    (async () => {
      if (user) {
        const getContacts = await DataStore.query(Contact, (c) =>
          c.universityID("eq", user.university.id)
        );
        setContacts(getContacts);
      }
    })();
  }, []);

  const campusContacts = contacts.filter((contact: any) =>
    contact.locations.includes("CAMPUS")
  );
  const communityContacts = contacts.filter((contact: any) =>
    contact.locations.includes("COMMUNITY")
  );
  const confidentialContacts = contacts.filter((contact: any) =>
    contact.conversation.includes("CONFIDENTIAL")
  );
  const nonconfidentialContacts = contacts.filter((contact: any) =>
    contact.conversation.includes("NONCONFIDENTIAL")
  );

  const saveChanges = async () => {
    try {
      if (user) {
        await DataStore.save(
          University.copyOf(user.university, (updated) => {
            updated.contact = info.contact;
            updated.policy = info.policy;
            updated.legal = info.legal;
          })
        );
      }
    } catch (err) {
      console.log("error updatingInfos", err);
    }
    window.location.reload();
  };

  return (
    <div className={styles.root}>
      {user && user.university && (
        <>
          <div className={styles.some}>
            <div className={styles.leftSide}>
              <div className={styles.infoItem}>
                <h3>Primary Contact Person:</h3>
                {info.contact ? (
                  !isFocused.contact ? (
                    <Typography>
                      {info.contact}
                      <EditIcon
                        className={styles.editIcon}
                        onClick={() => {
                          setIsFocused({ ...isFocused, contact: true });
                        }}
                      />
                    </Typography>
                  ) : (
                    <div className={styles.infoContainer}>
                      <TextField
                        autoFocus
                        className={styles.editIcon}
                        value={info.contact}
                        onChange={(event) =>
                          setInfo({ ...info, contact: event.target.value })
                        }
                      />
                      <div className={styles.buttons}>
                        <Button
                          className={styles.saveButton}
                          onClick={() => saveChanges()}
                        >
                          Save
                        </Button>
                        <Button
                          className={styles.cancelButton}
                          onClick={() =>
                            setIsFocused({ ...isFocused, contact: false })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )
                ) : (
                  "No contacts found"
                )}
              </div>
              <div className={styles.infoItem}>
                <h3>Policies:</h3>
                {info.policy ? (
                  !isFocused.policy ? (
                    <Typography>
                      {info.policy}
                      <EditIcon
                        className={styles.editIcon}
                        onClick={() => {
                          setIsFocused({ ...isFocused, policy: true });
                        }}
                      />
                    </Typography>
                  ) : (
                    <div className={styles.infoContainer}>
                      <TextField
                        className={styles.textField}
                        autoFocus
                        value={info.policy}
                        onChange={(event) =>
                          setInfo({ ...info, policy: event.target.value })
                        }
                      />
                      <div className={styles.buttons}>
                        <Button
                          className={styles.saveButton}
                          onClick={() => saveChanges()}
                        >
                          Save
                        </Button>
                        <Button
                          className={styles.cancelButton}
                          onClick={() =>
                            setIsFocused({ ...isFocused, policy: false })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )
                ) : (
                  "No policy found"
                )}
              </div>
              <div className={styles.infoItem}>
                <h3>Legal:</h3>
                {info.legal ? (
                  !isFocused.legal ? (
                    <Typography>
                      {info.legal}
                      <EditIcon
                        className={styles.editIcon}
                        onClick={() => {
                          setIsFocused({ ...isFocused, legal: true });
                        }}
                      />
                    </Typography>
                  ) : (
                    <div className={styles.infoContainer}>
                      <TextField
                        className={styles.editIcon}
                        autoFocus
                        value={info.legal}
                        onChange={(event) =>
                          setInfo({ ...info, legal: event.target.value })
                        }
                      />
                      <div className={styles.buttons}>
                        <Button
                          className={styles.saveButton}
                          onClick={() => saveChanges()}
                        >
                          Save
                        </Button>
                        <Button
                          className={styles.cancelButton}
                          onClick={() =>
                            setIsFocused({ ...isFocused, legal: false })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )
                ) : (
                  "No legals found"
                )}
              </div>
            </div>
            <div className={styles.rightSide}>
              <div className={styles.infoItem}>
                <h3>Resources:</h3>
                {contacts.length > 0 ? (
                  <>
                    {campusContacts.length > 0 && (
                      <span>
                        <div>
                          <p>On Campus: </p>
                        </div>
                        <div>
                          {campusContacts.map((contact: any) => {
                            return (
                              <div key={contact.id} className={styles.contact}>
                                <p>{contact.name}</p>
                                <p>{contact.email}</p>
                                <p>{contact.phone}</p>
                              </div>
                            );
                          })}
                        </div>
                      </span>
                    )}
                    {communityContacts.length > 0 && (
                      <span>
                        <div>
                          <p>On Community: </p>
                        </div>
                        <div>
                          {communityContacts.map((contact: any) => {
                            return (
                              <div key={contact.id} className={styles.contact}>
                                <p>{contact.name}</p>
                                <p>{contact.email}</p>
                                <p>{contact.phone}</p>
                              </div>
                            );
                          })}
                        </div>
                      </span>
                    )}
                    {confidentialContacts.length > 0 && (
                      <span>
                        <div>
                          <p>Confidential: </p>
                        </div>
                        <div>
                          {confidentialContacts.map((contact: any) => {
                            return (
                              <div key={contact.id} className={styles.contact}>
                                <p>{contact.name}</p>
                                <p>{contact.email}</p>
                                <p>{contact.phone}</p>
                              </div>
                            );
                          })}
                        </div>
                      </span>
                    )}
                    {nonconfidentialContacts.length > 0 && (
                      <span>
                        <div>
                          <p>Non confidential: </p>
                        </div>
                        <div>
                          {nonconfidentialContacts.map((contact: any) => {
                            return (
                              <div key={contact.id} className={styles.contact}>
                                <p>{contact.name}</p>
                                <p>{contact.email}</p>
                                <p>{contact.phone}</p>
                              </div>
                            );
                          })}
                        </div>
                      </span>
                    )}
                  </>
                ) : (
                  "No contacts found"
                )}
              </div>
              <div className={styles.infoItem}>
                <h3>Logo:</h3>
                {user.university.logo ? (
                  <AmplifyS3Image imgKey={user.university.logo} />
                ) : (
                  "No logo found"
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UniversityPage;
