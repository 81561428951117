import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { UserContext } from '../App';
import { setToLocalStorage } from '../utils/localStorage';
import { LocalStorageEnum } from '../utils/localStorage.enum';

export const useSignOut = (): [(e: React.MouseEvent) => Promise<void>] => {
  const { setUser } = useContext(UserContext);

  const cleanLocalStorage = () => {
    setToLocalStorage(LocalStorageEnum.USER, null);
    setToLocalStorage(LocalStorageEnum.IS_AUTHENTICATED, null);
    setToLocalStorage(LocalStorageEnum.ROLE, null);
  };

  const signOut = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      setUser(null);
      cleanLocalStorage();
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return [signOut];
};
