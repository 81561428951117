import { CircularProgress } from "@mui/material";
import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import {
  Playlist,
  Student,
  UniversityPlaylist,
  WatchedPlaylist,
} from "../../models";
import styles from "./Playlists.module.scss";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

function Playlists() {
  const { user } = useContext(UserContext);
  const [adminUniPlaylist, setAdminUniPlaylist] = useState<Playlist[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [calculated, setCalculated] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const queryPlaylists = async () => {
    const playlistsIDs = (await DataStore.query(UniversityPlaylist))
      .filter((up) => up.university.id === user?.university.id)
      .map((up) => up.playlist.id);

    const adminPlayList = (await DataStore.query(Playlist)).filter((p) =>
      playlistsIDs.includes(p.id)
    );

    const watchedPlaylist = await (
      await DataStore.query(WatchedPlaylist)
    ).filter((p: any) =>
      adminPlayList.map((s) => s.id).includes(p.playlist_id)
    );

    const students = (await DataStore.query(Student)).filter(
      (up) => up.universityID === user?.university.id
    );

    setStudents(students);
    filterPlaylists(watchedPlaylist, students);
    setAdminUniPlaylist(adminPlayList);
  };

  const filterPlaylists = (playlist: any, students: any) => {
    let data: any = {};
    playlist.forEach((play: any) => {
      students.forEach((stud: any) => {
        if (data[play.playlist_id] == null) data[play.playlist_id] = {};
        if (stud.completedPlaylists.includes(play.playlist_id)) {
          data[play.playlist_id] = {
            ...data[play.playlist_id],
            completed: [...(data[play.playlist_id]?.completed || []), stud.id],
          };
        } else if (play.status === "STARTED") {
          data[play.playlist_id] = {
            ...data[play.playlist_id],
            started: [...(data[play.playlist_id]?.started || []), stud.id],
          };
        }
      });
    });
    setCalculated(data);
  };

  useEffect(() => {
    (async () => {
      await queryPlaylists();
      setIsLoading(false);
    })();
  }, [isLoading]);

  return (
    <div className={styles.root}>
      <h3>Assigned Playlists</h3>
      {isLoading ? (
        <CircularProgress />
      ) : adminUniPlaylist.length !== 0 ? (
        <div>
          {adminUniPlaylist?.map((playlist) => (
            <div
              key={playlist.id}
              onClick={() => navigate(`/playlist/${playlist.id}`)}
            >
              <Doughnut
                data={{
                  labels: ["Completed", "Not started", "Started"],
                  datasets: [
                    {
                      data: [
                        (calculated[playlist.id]?.completed?.length || 0) /
                          students.length,
                        !calculated[playlist.id]
                          ? 1
                          : (students.length -
                              ((calculated[playlist.id]?.completed?.length ||
                                0) +
                                (calculated[playlist.id]?.started?.length ||
                                  0))) /
                            students.length,
                        (calculated[playlist.id]?.started?.length || 0) /
                          students.length,
                      ],
                      backgroundColor: ["#F46847", "#263061", "#27ACA9"],
                      borderWidth: 1,
                    },
                  ],
                }}
                height={200}
                width={200}
                options={{
                  events: [],
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: playlist.title,
                      padding: {
                        top: 10,
                      },
                    },
                    datalabels: {
                      formatter: function (value) {
                        if (!value) return "";
                        return Math.round(value * 100) + "%";
                      },
                      color: "white",
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        "No playlists found"
      )}
    </div>
  );
}

export default Playlists;