import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { UserContext } from "../../App";
import FeaturedInfo from "../FeaturedInfo/FeaturedInfo";
import Header from "../Header/Header";
import Navigation from "../Navigation/Navigation";
import PlaylistPage from "../Playlists/PlaylistPage/PlaylistPage";
import QuizPage from "../Playlists/PlaylistPage/QuizPage/QuizPage";
import SurveyPage from "../Playlists/PlaylistPage/SurveyPage/SurveyPage";
import VideoPage from "../Playlists/PlaylistPage/VideoPage/VideoPage";
import Playlists from "../Playlists/Playlists";
import StudentPage from "../StudentsList/StudentPage/StudentPage";
import StudentsList from "../StudentsList/StudentsList";
import UniversityPage from "../UniversityPage/UniversityPage";
import styles from "./Layout.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataStore } from "aws-amplify";
import { University } from "../../models";
import { useDatastoreStatus } from "../../hooks/useDataStoreStatus";
import { LocalStorageEnum } from "../../utils/localStorage.enum";
import { getFromLocalStorage } from "../../utils/localStorage";

function Layout() {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(true);
  const isAuthenticated = getFromLocalStorage(LocalStorageEnum.IS_AUTHENTICATED)
  const [isDatastoreReady] = useDatastoreStatus(isAuthenticated);

  const initUserData = async () => {
    if (user) {
      try {
        const universityDomain = user.email.split("@")[1];
        const university = await DataStore.query(University, (c) =>
          c.domain("eq", universityDomain)
        );
        setUser({ ...user, university: university[0] });
      } catch (err) {
        console.log("error while fetching university", err);
      }
      finally{
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    initUserData()
  }, []);

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress
            style={{ justifyContent: "center", position: "fixed", top: "55%" }}
          />
        </Box>
      ) : (
        <>
          <Header />
          <div className={styles.root}>
            <Navigation />
            <div className={styles.container}>
              <Routes>
                <Route path="/" element={<FeaturedInfo />} />
                <Route path="/playlists" element={<Playlists />} />
                <Route path="/students" element={<StudentsList />} />
                <Route path="/student/:id" element={<StudentPage />} />
                <Route path="/university" element={<UniversityPage />} />
                <Route path="/playlist/:id" element={<PlaylistPage />}>
                  <Route path="quiz/:id" element={<QuizPage />} />
                  <Route path="video/:id" element={<VideoPage />} />
                  <Route path="survey/:id" element={<SurveyPage />} />
                </Route>
              </Routes>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Layout;
