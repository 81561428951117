import { DataStore, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

export const useDatastoreStatus = (isAuthenticated = true): [boolean] => {
  const [isDatastoreReady, setIsDatastoreReady] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      const removeListener = Hub.listen('datastore', async capsule => {
        const {
          payload: { event, data },
        } = capsule;
        // console.log('DataStore event', capsule);
        // NOTE : event === ready (previous value)
        // need to verify event type
        if (event === 'ready') {
          setIsDatastoreReady(true);
        }
      });
      DataStore.start();
      return () => {
        removeListener();
      };
    }
  }, [isAuthenticated]);

  return [isDatastoreReady];
};
