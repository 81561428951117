import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import { LocalStorageEnum } from "../../utils/localStorage.enum";
import { Button, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Auth, Hub } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/gainfull_blue_enough_full.png";
import { setToLocalStorage } from "../../utils/localStorage";
import { DataStore } from "aws-amplify";
import { University } from "../../models";

function Login() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const adminError = "You are not authorized to access this page!";
  const [warningText, setWarningText] = useState(false);

  useEffect(() => {
    if (user && user.university?.activate === true) {
      navigate("/")
    } else if ( user && user.university?.activate === false) {
        localStorage.removeItem(LocalStorageEnum.IS_AUTHENTICATED);
        localStorage.removeItem(LocalStorageEnum.USER);
        localStorage.removeItem(LocalStorageEnum.ROLE);
        setWarningText(true);
    }
  }, [navigate, user]);

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { attributes } = await Auth.signIn({
        username: email,
        password,
      });
      setToLocalStorage(LocalStorageEnum.IS_AUTHENTICATED, true)
      const data = await Auth.currentAuthenticatedUser();

      if (!data.signInUserSession.accessToken.payload["cognito:groups"]) {
        localStorage.removeItem(LocalStorageEnum.IS_AUTHENTICATED);
        localStorage.removeItem(LocalStorageEnum.USER);
        localStorage.removeItem(LocalStorageEnum.ROLE);
        setUser(null);
        setIsLoading(false);
        toast.error(`${adminError}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      setToLocalStorage(LocalStorageEnum.USER, attributes)
      localStorage.setItem(
        LocalStorageEnum.ROLE,
        data.signInUserSession.accessToken.payload["cognito:groups"]
      );
      
      DataStore.start();
      await waitForDataStoreToLoad()

      if(attributes){
        const universityDomain = attributes.email.split("@")[1];
          const university = await DataStore.query(University, (c) =>
            c.domain("eq", universityDomain)
          );
        setUser({ ...attributes, university: university[0] });
        setIsLoading(false);
      }
    } catch (error) {
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setError(error);
      setIsLoading(false);
      toast.error(`${errorMessage}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const waitForDataStoreToLoad = async () => {
    await new Promise<void>((resolve) => {
      Hub.listen("datastore", async (capsule) => {
        const {event} = capsule.payload;
        if (event === 'ready') {
          resolve()
        }
      });
    })
  }

  return (
    <>
      <div className={styles.root}>
        <form onSubmit={signIn}>
          <img src={logo} height="100" width="150" />
          <p>Admin Login</p>
          {warningText && <p style={{color:"red"}}>Your organization’s account is not currently active. <br />Contact your organization for assistance</p>}
          <TextField
            name="email"
            type="email"
            value={email}
            label="Enter E-mail"
            placeholder="Enter E-mail"
            onChange={(e) => setEmail(e.target.value)}
            className={styles.inputs}
          />
          <TextField
            name="password"
            type="password"
            value={password}
            label="Enter Password"
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            className={styles.inputs}
          />
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            className={styles.inputs}
            sx={{
              ':hover': {
                bgcolor: '#263061', // theme.palette.primary.main
                color: 'white',
              },
            }}
          
          >
            Login
          </LoadingButton>
        </form>
      </div>
      {(error || adminError) && <ToastContainer />}
    </>
  );
}

export default Login;
