import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { DataStore } from "aws-amplify";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  Slide,
} from "pure-react-carousel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Answers,
  Question,
  Quiz,
  Student,
  Survey,
} from "../../../../models";
import styles from "./SurveyPage.module.scss";
import { UserContext } from "../../../../App";
import StudentsList from "../../../StudentsList/StudentsList";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

function SurveyPage() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [calculated, setCalculated] = useState<any>({});
  const [survey, setSurvey] = useState<Survey>();
  const [questions, setQuestions] = useState<Question[]>();
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const students = (await DataStore.query(Student)).filter(
      (up) => up.universityID === user?.university.id
    );

    setStudents(students);

    if (id) {
      const getSurvey = await DataStore.query(Survey, (c) => c.id("eq", id));
      setSurvey(getSurvey[0]);
      const getQuestions = await DataStore.query(Question, (c) =>
        c.surveyID("eq", id)
      );
      setQuestions(getQuestions);
      const getAnswers = await DataStore.query(Answers);
      setAnswers(getAnswers);
      filterAnswers(getQuestions, students, getAnswers);
    }
    setIsLoading(false);
  };

  const filterAnswers = (questions: any, students: any, answers: any) => {
    let data: any = {};
    questions.forEach((question: any) => {
      students.forEach((student: any) => {
        answers.forEach((answer: any) => {
          if (
            student.id === answer.student_id &&
            question.id === answer.question_id
          ) {
            data[answer.question_id] = {
              options: [],
            };
            question.options?.forEach((option: any) => {
              data[answer.question_id].options.push({
                name: option,
                items:
                  option === answer.answer
                    ? students
                        .filter((s: any) => s.id === answer.student_id)
                        .map((s: any) => s.id)
                    : [],
              });
            });
          }
        });
      });
    });
    setCalculated(data);
  };

  useEffect(() => {
    fetchData();
  }, [isLoading, id]);

  return (
    <div className={styles.root}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        survey && (
          <>
            <h3>{survey.title}</h3>
            {questions && (
              <CarouselProvider
                naturalSlideWidth={50}
                naturalSlideHeight={20}
                visibleSlides={1}
                totalSlides={questions.length}
                currentSlide={0}
                className={styles.carousel}
              >
                <Slider>
                  {questions.map((question, i) => {
                    return (
                      <div key={question.id}>
                        <Slide index={i}>
                          {question.title}
                          <div style={{ height: "auto" }}>
                          {calculated[question.id] ? (
                            <Doughnut
                              data={{
                                labels: question.options.map(
                                  (option) => option
                                ),
                                datasets: [
                                  {
                                    data: calculated[question.id]?.options.map(
                                      (key: any) => {
                                        return key.items.length !== 0
                                          ? key.items.length / students.length
                                          : 0 / students.length;
                                      }
                                    ),
                                    backgroundColor: [
                                      "#F46847",
                                      "#263061",
                                      "#27ACA9",
                                      "#FFFF00",
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              options={{
                                events: [],
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                  datalabels: {
                                    formatter: function (value) {
                                      if (!value) return "";
                                      return Math.round(value * 100) + "%";
                                    },
                                    color: "white",
                                  },
                                },
                              }}
                            />) : 
                            <p>No data available</p>
                            }
                          </div>
                        </Slide>
                      </div>
                    );
                  })}
                </Slider>
                <>
                  <ButtonBack className={styles.back_button}>
                    <ArrowBackIosNewIcon />
                  </ButtonBack>
                  <ButtonNext className={styles.next_button}>
                    <ArrowForwardIosIcon />
                  </ButtonNext>
                </>
              </CarouselProvider>
            )}
          </>
        )
      )}
    </div>
  );
}

export default SurveyPage;
