export const setToLocalStorage = (key: string, value: any): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  export const getFromLocalStorage = (key: string): any => {
    try {
      return JSON.parse(localStorage.getItem(key) || '');
    } catch (e) {
      return undefined;
    }
  };
  