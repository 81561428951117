import React, { useState } from "react";
import styles from "./App.module.scss";
import Login from "./components/Login/Login";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { LocalStorageEnum } from "./utils/localStorage.enum";
import NotFound from "./components/NotFound/NotFound";
import RequireAuth from "./components/Auth/RequireAuth";
import Amplify, { Auth, DataStore } from "aws-amplify";
import awsExports from "./aws-exports";
import StudentsList from "./components/StudentsList/StudentsList";
import Layout from "./components/Layout/Layout";
import Playlists from "./components/Playlists/Playlists";
import StudentPage from "./components/StudentsList/StudentPage/StudentPage";
import UniversityPage from "./components/UniversityPage/UniversityPage";
import PlaylistPage from "./components/Playlists/PlaylistPage/PlaylistPage";
import QuizPage from "./components/Playlists/PlaylistPage/QuizPage/QuizPage";
import VideoPage from "./components/Playlists/PlaylistPage/VideoPage/VideoPage";
import SurveyPage from "./components/Playlists/PlaylistPage/SurveyPage/SurveyPage";
import FeaturedInfo from "./components/FeaturedInfo/FeaturedInfo";
import { getFromLocalStorage, setToLocalStorage } from "./utils/localStorage";
import { University } from "./models";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

Amplify.configure(awsExports);

type User = {
  email: string;
  password: string;
  university: University;
};

const userContextInitialValue: {
  user: User | null;
  setUser: React.Dispatch<User | null>;
} = {
  user: null,
  setUser: () => {},
};

export const UserContext = React.createContext(userContextInitialValue);

function App() {
  const [user, setUser] = useState<User | null>(null);
  const accountContextValue = { user, setUser };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentUserData = localStorage.getItem(LocalStorageEnum.USER) || "";
    if (currentUserData) {
      const currentUser = JSON.parse(currentUserData);
      setUser(currentUser);
    }
    setLoading(false);
  }, []);

  return (
    <div className={styles.root}>
      {loading ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress
            style={{ justifyContent: "center", position: "fixed", top: "55%" }}
          />
        </Box>
      ) : (
        <UserContext.Provider value={accountContextValue}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route element={<Layout />}>
                <Route path="/" element={<FeaturedInfo />} />
                <Route path="/playlists" element={<Playlists />} />
                <Route path="/students" element={<StudentsList />} />
                <Route path="/student/:id" element={<StudentPage />} />
                <Route path="/university" element={<UniversityPage />} />
                <Route path="/playlist/:id" element={<PlaylistPage />}>
                  <Route path="quiz/:id" element={<QuizPage />} />
                  <Route path="video/:id" element={<VideoPage />} />
                  <Route path="survey/:id" element={<SurveyPage />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </UserContext.Provider>
      )}
    </div>
  );
}

export default App;
