import { CircularProgress } from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Video } from "../../../../models";
import styles from "./VideoPage.module.scss";

function VideoPage() {
  const { id } = useParams();
  const [video, setVideo] = useState<Video>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      if (id) {
        const getVideo = await DataStore.query(Video, (c) => c.id("eq", id));
        setVideo(getVideo[0]);
        setIsLoading(false);
      }
    })();
  }, [isLoading, id]);
  const url = video?.englishUrl;

  return (
    <div className={styles.root}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        video && (
          <>
            <h3>{video.title}</h3>
            <Vimeo responsive video={video?.englishUrl || ""} />
          </>
        )
      )}
    </div>
  );
}

export default VideoPage;
