import React, { useContext, useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { NavLink } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { UserContext } from "../../App";
import { Playlist, Student, UniversityPlaylist } from "../../models";
import styles from "./FeaturedInfo.module.scss";

function FeaturedInfo() {
  const { user } = useContext(UserContext);
  const [adminUniPlaylist, setAdminUniPlaylist] = useState<Playlist[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const queryPlaylists = async () => {
    const playlistsIDs = (await DataStore.query(UniversityPlaylist))
      .filter((up) => up.university.id === user?.university.id)
      .map((up) => up.playlist.id);

    const adminPlayList = (await DataStore.query(Playlist)).filter((p) =>
      playlistsIDs.includes(p.id)
    );

    const students = (await DataStore.query(Student)).filter(
      (up) => up.universityID === user?.university.id
    );

    setStudents(students);
    setAdminUniPlaylist(adminPlayList);
    setIsLoading(false);
  };

  useEffect(() => {
    queryPlaylists();
  }, [isLoading]);

  return (
    <div className={styles.featured}>
      <div className={styles.featuredItem}>
        <span className={styles.featuredTitle}>Total Students</span>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className={styles.featuredContainer}>
              <span className={styles.featuredNumber}>{students.length}</span>
            </div>
            <div className={styles.featuredDetails}>
              <NavLink to="/students" className={styles.featuredLink}>
                See details
                <ArrowForwardIcon className={styles.featuredIcon} />
              </NavLink>
            </div>
          </>
        )}
      </div>
      <div className={styles.featuredItem}>
        <span className={styles.featuredTitle}>Total Playlists</span>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className={styles.featuredContainer}>
              <span className={styles.featuredNumber}>
                {adminUniPlaylist.length}
              </span>
            </div>
            <div className={styles.featuredDetails}>
              <NavLink to="/playlists" className={styles.featuredLink}>
                See details
                <ArrowForwardIcon className={styles.featuredIcon} />
              </NavLink>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FeaturedInfo;
