import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { Student, Playlist, Content } from "../../models";
import styles from "./StudentsList.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface DisplayStudent extends Student {
  studentPlaylists: {
    id: string | undefined;
    title: string | undefined;
  }[];
  studentContents: {
    id: string | undefined;
    title: string | undefined;
  }[];
}

function StudentsList() {
  const { user } = useContext(UserContext);
  const [allStudents, setAllStudents] = useState<Student[]>();
  const [allPlaylists, setAllPlaylists] = useState<Playlist[]>();
  const [allContents, setAllContents] = useState<Content[]>();
  const [displayStudents, setDisplayStudents] = useState<DisplayStudent[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<any>();
  const [orderBy, setOrderBy] = useState();
  const history = useNavigate();
  const uniqueContentTitle = [] as any;
  const uniquePlayListTitle = [] as any;

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchStudentsPlaylist = async () => {
    if (allStudents && allPlaylists && allContents) {
      const studentsEnrolledInAdminsUniversity = await Promise.all(
        allStudents
          .filter((s) => s.universityID === user?.university.id)
          .map(async (s) => {
            const completedPlaylists = await Promise.all(
              s.completedPlaylists.map(async (cP: any) => {
                const playlist = allPlaylists.find((p) => p.id === cP);

                return {
                  id: playlist?.id,
                  title: playlist?.title,
                };
              })
            );

            const completedContents = await Promise.all(
              s.completedContents.map(async (cP: any) => {
                const content = allContents.find((p) => p.id === cP);

                return {
                  id: content?.id,
                  title: content?.title,
                };
              })
            );

            return {
              ...s,
              studentPlaylists: completedPlaylists,
              studentContents: completedContents,
            };
          })
      );
      setDisplayStudents(studentsEnrolledInAdminsUniversity);
    }
  };

  useEffect(() => {
    (async () => {
      const getStudents = await DataStore.query(Student);
      setAllStudents(getStudents);
      const getPlaylists = await DataStore.query(Playlist);
      setAllPlaylists(getPlaylists);
      const getContents = await DataStore.query(Content);
      setAllContents(getContents);
      await fetchStudentsPlaylist();
      setIsLoading(false);
    })();
  }, [isLoading]);

  const headCells = [
    {
      id: "username",
      label: "Username",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "language",
      label: "Language",
    },
    {
      id: "gender",
      label: "Gender",
    },
  ];

  const handleSortRequest = (cellId: any) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const sortTable = (array: any, comparator: any) => {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((e1: any) => e1[0]);
  };

  const getComparator = (order: any, orderBy: any) => {
    return order === "desc"
      ? (a: any, b: any) => descendingComperator(a, b, orderBy)
      : (a: any, b: any) => -descendingComperator(a, b, orderBy);
  };

  const descendingComperator = (a: any, b: any, orderBy: any) => {
        if(b[orderBy] < a[orderBy]){
          return -1
        }
        if(b[orderBy] > a[orderBy]){
          return 1
        }
        return 0
  }

  return (
    <div className={styles.root}>
      <h3>Enrolled Students</h3>
      {isLoading ? (
        <CircularProgress />
      ) : displayStudents ? (
        displayStudents?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className={styles.table_head}>
                  {headCells.map((headCell: any) => (
                    <TableCell className={styles.tableCell} key={headCell.id}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell className={styles.tableCell}>
                    Completed Contents
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    Completed Playlists
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortTable(displayStudents, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((student: any) => (
                    <TableRow
                      key={student.id}
                      onClick={() => history(`/student/${student.id}`)}
                      className={styles.tableRow}
                    >
                      <TableCell component="th" scope="student">
                        {student.username}
                      </TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>{student.language}</TableCell>
                      <TableCell>{student.gender}</TableCell>
                      {student.studentContents.length !== 0 ? (
                        <TableCell>
 {student.studentContents.filter((element: any) => {
  const isDuplicate = uniqueContentTitle?.includes(element?.title);

  if (!isDuplicate) {
    uniqueContentTitle?.push(element?.title );

    return true;
  }

  return false;
}).map((item:any) => item?.title + ", ")}

                        </TableCell>
                      ) : (
                        <TableCell>-</TableCell>
                      )}
                      {student.studentPlaylists.length !== 0 ? (
                        <TableCell>
                          {student.studentPlaylists.filter((element: any) => {
  const isDuplicate = uniquePlayListTitle?.includes(element?.title);

  if (!isDuplicate) {
    uniquePlayListTitle?.push(element?.title );

    return true;
  }

  return false;
}).map((item:any) => item?.title + ", ")}
                        </TableCell>
                      ) : (
                        <TableCell>-</TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={displayStudents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <p>NO STUDENTS ENROLLED TO THIS UNIVERSITY</p>
        )
      ) : (
        <p>NO STUDENTS ENROLLED TO THIS UNIVERSITY</p>
      )}
    </div>
  );
}

export default StudentsList;
