import styles from './NotFound.module.scss';
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className={styles.root}>
    <div className={styles.err}>404</div>
    <div className={styles.msg}>Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?<p>Let's go <a href="/">home</a> and try from there.</p></div>
      </div>
  );
}

export default NotFound;
